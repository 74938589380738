@import '../../../layout/Style/Colors';
@import '../../../layout/Style/Fonts';


.modalContainer { 
    flex-direction: column;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {    
    flex-direction: column;
    align-content: center;    
    background-color: $color-second;    
}
.acceptContainer {   
    align-self: center; 
    align-content: center;
    position: absolute;
    bottom: 0%;
    width: 100%;
    height: 30%;
    margin-top: 20px;
    padding-top: 15px;
    flex-direction: column;
    background-color: white;
    border-radius: 30px;
}
.messageContainer {
    align-items: center;
    align-self: center;
    align-content: center;
}
.acceptButton {
    text-transform: uppercase;
    border: 1px solid $color-second;
    margin: 10px; 
    padding: 10px 20px;   
    background-color: $color-second;
    color: white;
    align-self: center;
    width: 200px;
    border-radius: 50px;
    cursor: pointer;
    &.alternative{
        background-color: white;
        color: $color-second;
        border-color: #999;
    }
}
.headerText {
    color: white;
    padding: 10px;
    font-family: "Cabin-Bold";    
    align-self: center;
    text-align: center;
}
.bodyText {
    padding:20px;
    color: white;
    font-family: "Cabin-Regular";
    text-align: center;
}
.acceptText { 
    padding: 10px;
    color: black;
    font-family: "Cabin-Regular";
    text-align: center;
    align-self: center;
}

.modal {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modalContent {
    background-color: $color-second;    
    padding: 10px;
    outline: none;
    border-radius: 4px;
  
    .infoText {
      display: flex;
      margin-bottom: 20px;      
      align-items: center;
      flex-direction: column;
    }
  
    .buttonContainer {            
      margin-top: 20px;
      background-color: white;
      border-radius: 20px;
    }    
  
    
  }

  .center {    
    text-align: center;
    width: 100%;
  }
