$color-main: #73c9e2;
$color-second: #38a65b;
$color-third: #f08715;
$color-fourth: #eb6099;
$color-light: #fff;
$color-dark: #000;
$color-second-light: #c9c937;

$color-error: #f44336;
$color-success: #4caf50;

:export {
    colorMain: $color-main;
    colorSecond: $color-second;
    colorThird: $color-third;
    colorFourth: $color-fourth;
    colorLight: $color-light;
    colorDark: $color-dark;

    colorError: $color-error;
    colorSuccess: $color-success;
}
