.table {
  min-width: 650
}

.retailerImage {
  height: 50px; 
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}