@import '../../layout/Style/Colors';

.Account {
    background-color: $color-second;
    height: 100vh;
    overflow: auto;
    padding: 20px;

    .Spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -12px;
    }

    .Logo {
        width: 250px;
        height: auto;
    }
}

.Account .MuiCard-root {
    max-width: 300px;
    margin: 0px auto;
    text-align: left;

    .MuiCardMedia-root {
        background-color: $color-second;
        width: 100%;
        height: 100px;
        background-size: auto;
    }

}