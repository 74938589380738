@import "../../scss/base";

#main.home .nav {
    display: none;
}

#main.home #content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
}