.retailerSettings {
    margin: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#couponEmployees {
    .MuiTable-root {
        .MuiTableHead-root {
            .MuiTableRow-head {
                .MuiTableCell-head {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: top;

                    .MuiInput-root {
                        width: 100%;
                    }
                }
            }
        }
    }
}


.uploadInformation {
    p.MuiTypography-subtitle2 {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.dropzone {
    cursor: pointer;
}

.dropzone:focus {
    outline: none;
}