/* Container für die gesamte Komponente */
.recruitingStatistics {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 20px;
    margin-right: 100px;
    margin-left: 100px;
    height: 100%;
  }

.recruitingStatisticsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* Oberer Bereich */
  .top-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  /* Stil für jede Box */
  .box {
    flex: 1;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    text-align: center;
  }
  
  /* Inhalt der Box */
  .box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .box-text {
    font-family: "Cabin";
  }
  
  /* Graue Trennlinie */
  .box-divider {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin: 10px 0;
  }
  
  /* Stil für die Zahl in der Box */
  .box-number {
    font-size: 40px;
    color: #8d8d8d;
    font-weight: bold;
    font-family: "Cabin";
  }
  
  /* Unterer Bereich (Tabelle) */
  .bottom-section {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    margin-top: 20px;
    overflow: auto;
  }
  
  /* Stil für die Tabelle */
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Stil für Tabellenkopf */
  .table th {
    padding: 8px;
    text-align: left;
    font-family: "Cabin";
  }
  
  /* Stil für Tabellenzellen */
  .table td {
    border-top: 1px solid #e1e1e1;
    padding: 8px;
    text-align: left;
    font-family: "Cabin";
  }
  