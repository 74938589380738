.dummyImage {
    height: 600px;
    width: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@import '../../../layout/Style/Colors';

.ActivateForm {
    .enough td {
        font-weight: bold;
        color: $color-success;
        border-bottom: none;
    }

    .notEnough td {
        color: $color-error;
    }
}

.card {
    width: 300px;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.buttonArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ActivateCoupon {
    .couponList {
        opacity: 0;
        transition: opacity 0.2s linear;
    }

    .startValue {
        opacity: 0;
        transform: opacity 0.2s linear;
        display: none;
    }

    &.multiple {
        .couponList {
            opacity: 1;
        }

        .startValue {
            display: block;
            opacity: 1;
        }
    }
}