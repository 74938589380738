@import "layout/Style/Colors";

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.ButtonSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}

.NewStoreStepperButtons {
    margin-top: 20px;
    position: relative;

    .NextButtons {
        text-align: right;

        button {
            margin-left: 16px;
        }
    }

    .PrevButtons {
        button {
            margin-right: 16px;
        }
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.dropzoneContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-width: 2;
    border-radius: 2;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    .error {
        border-color: $color-error;
    }

    img:hover {
        opacity: 0.7;
        cursor: pointer;
    }
}

.react-pdf__Page__canvas {
    height: auto !important;
    max-width: 100%;

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
}
