@font-face {
  font-family: 'keeplocal';
  src:  url('keeplocal.eot?hbelvf');
  src:  url('keeplocal.eot?hbelvf#iefix') format('embedded-opentype'),
    url('keeplocal.ttf?hbelvf') format('truetype'),
    url('keeplocal.woff?hbelvf') format('woff'),
    url('keeplocal.svg?hbelvf#keeplocal') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="kl-icon-"], [class*=" kl-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'keeplocal' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kl-icon-Icon_Information:before {
  content: "\e944";
}
.kl-icon-Icon_Bonus:before {
  content: "\e939";
}
.kl-icon-Icon_Counter:before {
  content: "\e93a";
}
.kl-icon-Icon_Counter_Small:before {
  content: "\e93b";
}
.kl-icon-Icon_Gift:before {
  content: "\e93c";
}
.kl-icon-Icon_Link:before {
  content: "\e93d";
}
.kl-icon-Icon_Mail:before {
  content: "\e93e";
}
.kl-icon-Icon_Mail_Small:before {
  content: "\e93f";
}
.kl-icon-Icon_Promote_01:before {
  content: "\e940";
}
.kl-icon-Icon_Promote_02:before {
  content: "\e941";
}
.kl-icon-Icon_Promote_02_Small:before {
  content: "\e942";
}
.kl-icon-Icon_Share:before {
  content: "\e943";
}
.kl-icon-KL-Icon-Check-Grey .path1:before {
  content: "\e92b";
  color: rgb(89, 164, 99);
}
.kl-icon-KL-Icon-Check-Grey .path2:before {
  content: "\e92c";
  margin-left: -1.0166015625em;
  color: rgb(108, 108, 108);
}
.kl-icon-KL-Icon-Check-White .path1:before {
  content: "\e92d";
  color: rgb(89, 164, 99);
}
.kl-icon-KL-Icon-Check-White .path2:before {
  content: "\e92e";
  margin-left: -1.0166015625em;
  color: rgb(255, 255, 255);
}
.kl-icon-KL-Icon-Info .path1:before {
  content: "\e92f";
  color: rgb(88, 164, 99);
}
.kl-icon-KL-Icon-Info .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.kl-icon-KL-Icon-Loading .path1:before {
  content: "\e931";
  color: rgb(221, 80, 58);
}
.kl-icon-KL-Icon-Loading .path2:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.kl-icon-KL-Icon-Loading .path3:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.kl-icon-KL-Icon-Loading .path4:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.kl-icon-KL-Icon-Loading .path5:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.kl-icon-KL-Icon-Loading .path6:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.kl-icon-KL-Icon-Loading .path7:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.kl-icon-KL-Icon-Loading .path8:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.kl-icon-KL-Icon-Cart:before {
  content: "\e92a";
}
.kl-icon-climatepartner:before {
  content: "\e925";
}
.kl-icon-oekostrom:before {
  content: "\e927";
}
.kl-icon-papierverbrauch:before {
  content: "\e928";
}
.kl-icon-vegan:before {
  content: "\e929";
}
.kl-icon-e-car:before {
  content: "\e91d";
}
.kl-icon-recycle:before {
  content: "\e91e";
}
.kl-icon-handshake:before {
  content: "\e91f";
}
.kl-icon-letter-alt:before {
  content: "\e920";
}
.kl-icon-heart:before {
  content: "\e921";
}
.kl-icon-zero:before {
  content: "\e922";
}
.kl-icon-check:before {
  content: "\e923";
}
.kl-icon-trophy:before {
  content: "\e924";
}
.kl-icon-letter-open:before {
  content: "\e926";
}
.kl-icon-added-to-cart:before {
  content: "\e900";
}
.kl-icon-baptism:before {
  content: "\e901";
}
.kl-icon-barcode:before {
  content: "\e902";
}
.kl-icon-birth:before {
  content: "\e903";
}
.kl-icon-birthday:before {
  content: "\e904";
}
.kl-icon-card:before {
  content: "\e905";
}
.kl-icon-card-creation:before {
  content: "\e906";
}
.kl-icon-card-creation-deluxe:before {
  content: "\e907";
}
.kl-icon-cart:before {
  content: "\e908";
}
.kl-icon-christmas:before {
  content: "\e909";
}
.kl-icon-easter:before {
  content: "\e90a";
}
.kl-icon-email:before {
  content: "\e90b";
}
.kl-icon-euro:before {
  content: "\e90c";
}
.kl-icon-fathers-day:before {
  content: "\e90d";
}
.kl-icon-gift:before {
  content: "\e90e";
}
.kl-icon-giftcard:before {
  content: "\e90f";
}
.kl-icon-letter:before {
  content: "\e910";
}
.kl-icon-list:before {
  content: "\e911";
}
.kl-icon-mothers-day:before {
  content: "\e912";
}
.kl-icon-no-cause:before {
  content: "\e913";
}
.kl-icon-pen:before {
  content: "\e914";
}
.kl-icon-post:before {
  content: "\e915";
}
.kl-icon-print:before {
  content: "\e916";
}
.kl-icon-settings:before {
  content: "\e917";
}
.kl-icon-store:before {
  content: "\e918";
}
.kl-icon-thank-you:before {
  content: "\e919";
}
.kl-icon-valentines-day:before {
  content: "\e91a";
}
.kl-icon-wedding:before {
  content: "\e91b";
}
.kl-icon-wish-granter:before {
  content: "\e91c";
}
