@import '../../../layout/Style/Colors';

.RedeemForm {
    .enough td {
        font-weight: bold;
        color: $color-success;
        border-bottom: none;
    }

    .notEnough td {
        color: $color-error;
    }

    .redeemTransactions {
        tbody {
            tr:last-child td {
                border-bottom: none;
            }
        }
    }
}

.dummyImageRedeem {
    width: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.countCircle {
    background-color: red;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.countText {
    color: white;
    font-size: 18px;
    margin-bottom: 1px;
}