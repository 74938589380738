@import '../../../layout/Style/Colors.scss';

.shareIcon {
    background-color: $color-second-light;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;
    color: white;
    font-size: 32px;
}
.copyLinkIcon {
    background-color: $color-fourth;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;
    color: white;
    font-size: 32px;
}