@import '../../layout/Style/Colors';

.skipStepWarning {
    p {
        margin-top: 0px;
    }

    a {
        color: $color-main;
    }
}