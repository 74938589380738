@import "../../../scss/base";

#tilesContainer {
    display: flex;
    justify-content: flex-start;
    padding: 30px;
    height: 100%;

    .tiles {
        width: 284px;
        flex-shrink: 0;

        &.single-tile {
            width: 134px;
        }

        &.single-column {
            width: 100%;
        }

        header {
            color: $color-third;
            font-size: 22px;
            margin-bottom: 12px;
        }

        &>div {
            a {
                display: flex;
                align-items: stretch;
                justify-content: stretch;
                width: 134px;
                height: 134px;
                margin-bottom: 16px;
                float: left;
                text-align: center;
                text-decoration: none;

                svg {
                    width: 60px;
                    height: 60px;
                }

                &:nth-child(2n) {
                    margin-left: 16px;
                }

                span {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .MuiPaper-root {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
            }
        }
    }
}

#tilesContainer {
    .tiles {
        a {
            opacity: 0;
            transition: opacity 0.3s linear;
        }
    }

    .tiles {
        .revealed {
            a {
                opacity: 1;
            }
        }
    }
}

.tilesContainerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}