@font-face {
    font-family: 'Cabin';
    src: url('../../fonts/Cabin/Cabin-BoldItalic.woff2') format('woff2'),
        url('../../fonts/Cabin/Cabin-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Cabin';
    src: url('../../fonts/Cabin/Cabin-SemiBoldItalic.woff2') format('woff2'),
        url('../../fonts/Cabin/Cabin-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Cabin';
    src: url('../../fonts/Cabin/Cabin-Regular.woff2') format('woff2'),
        url('../../fonts/Cabin/Cabin-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cabin';
    src: url('../../fonts/Cabin/Cabin-SemiBold.woff2') format('woff2'),
        url('../../fonts/Cabin/Cabin-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Cabin';
    src: url('../../fonts/Cabin/Cabin-Bold.woff2') format('woff2'),
        url('../../fonts/Cabin/Cabin-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cabin';
    src: url('../../fonts/Cabin/Cabin-Medium.woff2') format('woff2'),
        url('../../fonts/Cabin/Cabin-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cabin';
    src: url('../../fonts/Cabin/Cabin-Italic.woff2') format('woff2'),
        url('../../fonts/Cabin/Cabin-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Cabin';
    src: url('../../fonts/Cabin/Cabin-MediumItalic.woff2') format('woff2'),
        url('../../fonts/Cabin/Cabin-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'keeplocal';
    src:  url('../../fonts/keeplocal/keeplocal.eot?143xmb');
    src:  url('../../fonts/keeplocal/keeplocal.eot?143xmb#iefix') format('embedded-opentype'),
      url('../../fonts/keeplocal/keeplocal.ttf?143xmb') format('truetype'),
      url('../../fonts/keeplocal/keeplocal.woff?143xmb') format('woff'),
      url('../../fonts/keeplocal/keeplocal.svg?143xmb#keeplocal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="kl-icon-"], [class*=" kl-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'keeplocal' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .kl-icon-climatepartner:before {
    content: "\e925";
  }
  .kl-icon-oekostrom:before {
    content: "\e927";
  }
  .kl-icon-papierverbrauch:before {
    content: "\e928";
  }
  .kl-icon-vegan:before {
    content: "\e929";
  }
  .kl-icon-e-car:before {
    content: "\e91d";
  }
  .kl-icon-recycle:before {
    content: "\e91e";
  }
  .kl-icon-handshake:before {
    content: "\e91f";
  }
  .kl-icon-letter-alt:before {
    content: "\e920";
  }
  .kl-icon-heart:before {
    content: "\e921";
  }
  .kl-icon-zero:before {
    content: "\e922";
  }
  .kl-icon-check:before {
    content: "\e923";
  }
  .kl-icon-trophy:before {
    content: "\e924";
  }
  .kl-icon-letter-open:before {
    content: "\e926";
  }
  .kl-icon-added-to-cart:before {
    content: "\e900";
  }
  .kl-icon-baptism:before {
    content: "\e901";
  }
  .kl-icon-barcode:before {
    content: "\e902";
  }
  .kl-icon-birth:before {
    content: "\e903";
  }
  .kl-icon-birthday:before {
    content: "\e904";
  }
  .kl-icon-card:before {
    content: "\e905";
  }
  .kl-icon-card-creation:before {
    content: "\e906";
  }
  .kl-icon-card-creation-deluxe:before {
    content: "\e907";
  }
  .kl-icon-cart:before {
    content: "\e908";
  }
  .kl-icon-christmas:before {
    content: "\e909";
  }
  .kl-icon-easter:before {
    content: "\e90a";
  }
  .kl-icon-email:before {
    content: "\e90b";
  }
  .kl-icon-euro:before {
    content: "\e90c";
  }
  .kl-icon-fathers-day:before {
    content: "\e90d";
  }
  .kl-icon-gift:before {
    content: "\e90e";
  }
  .kl-icon-giftcard:before {
    content: "\e90f";
  }
  .kl-icon-letter:before {
    content: "\e910";
  }
  .kl-icon-list:before {
    content: "\e911";
  }
  .kl-icon-mothers-day:before {
    content: "\e912";
  }
  .kl-icon-no-cause:before {
    content: "\e913";
  }
  .kl-icon-pen:before {
    content: "\e914";
  }
  .kl-icon-post:before {
    content: "\e915";
  }
  .kl-icon-print:before {
    content: "\e916";
  }
  .kl-icon-settings:before {
    content: "\e917";
  }
  .kl-icon-store:before {
    content: "\e918";
  }
  .kl-icon-thank-you:before {
    content: "\e919";
  }
  .kl-icon-valentines-day:before {
    content: "\e91a";
  }
  .kl-icon-wedding:before {
    content: "\e91b";
  }
  .kl-icon-wish-granter:before {
    content: "\e91c";
  }