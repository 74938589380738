.export-transactions-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h2 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.filter-section {
    margin-top: 20px;
}

.filter-section label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
}

.dropdown {
    margin-bottom: 20px;
}

.dropdown-button {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f7f7f7;
    cursor: pointer;
    text-align: left;
}

.warning-text {
    color: red;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
}

.transaction-section {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background: #f7f7f7;
}

.period-section {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background: #f7f7f7;
}

.user-selection {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background: #f7f7f7;
}

.user-option {
    margin: 5px 0;
}

.filter-reset {
    text-align: center;
    margin: 20px 0;
}

.filter-reset button {
    background: none;
    border: none;
    color: #00a8e8;
    cursor: pointer;
    text-decoration: underline;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cancel-button {
    background: none;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.export-button {
    background: #00a8e8;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.arrow-closed {
    top: 10px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.arrow-open {
    top: 14px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
