@import "../../../layout/Style/Colors";

#preview {
    width: 100%;
    max-width: 260px;

    .outerBorder {
        background-color: $color-dark;
        padding-top: 1127.34px / 591.44px * 100%;
        border-radius: 20px;
        position: relative;
    }

    .innerBorder {
        position: absolute;
        top: 1.5%;
        left: 2.7%;
        right: 2.7%;
        bottom: 1.5%;
        border-radius: 12px;
        background-color: $color-main;
        padding: 10% 5% 5% 5%;
    }

    .notch {
        position: absolute;
        top: 0px;
        width: 50%;
        left: 50%;
        margin-left: -25%;
        background-color: $color-dark;
        height: 3%;
        border-radius: 0px 0px 8px 8px;
    }

    .previewCoupon {
        background-color: $color-light;
        color: $color-dark;
        width: 100%;
        height: 18%;
        position: relative;
        display: flex;
        justify-content: stretch;
        align-items: stretch;

        &::before {
            content: '';
            display: block;
            width: 5%;
            padding-bottom: 5%;
            border-radius: 10000px;
            background-color: $color-main;
            position: absolute;
            left: -2.5%;
            top: 50%;
            margin-top: -2.5%;
        }

        &::after {
            content: '';
            display: block;
            width: 5%;
            padding-bottom: 5%;
            border-radius: 10000px;
            background-color: $color-main;
            position: absolute;
            right: -2.5%;
            top: 50%;
            margin-top: -2.5%;
        }

        .image {
            width: 40%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                object-fit: contain;
                width: 100%;
                height: auto;
                max-height: 100%;
            }
        }

        .info {
            flex: 1 1 auto;
            padding: 2% 5% 2% 5%;

            .value {
                font-weight: 900;
                font-size: 170%;
            }

            .name {
                font-weight: 900;
                font-size: 70%;
            }
        }
    }
}